var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'customer',
    'plan',
    'validity_from',
    'validity_to',
    'fee',
    'inscription_fee',
    'total' ],"formats":{
    validity_from: 'Date',
    validity_to: 'Date',
    fee: 'Money',
    inscription_fee: 'Money',
    total: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"memberships"}})}
var staticRenderFns = []

export { render, staticRenderFns }