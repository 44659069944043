<template>
  <Browse
    :columns="[
      'customer',
      'plan',
      'validity_from',
      'validity_to',
      'fee',
      'inscription_fee',
      'total',
    ]"
    :formats="{
      validity_from: 'Date',
      validity_to: 'Date',
      fee: 'Money',
      inscription_fee: 'Money',
      total: 'Money',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="memberships"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
